.bloco{
    border-radius: 6px;
}

/* testimonial.css */

.testimonial-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

@media (max-width: 768px) {
    .testimonial-grid {
        grid-template-columns: 1fr;
    }
}
