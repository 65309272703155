#logo-fg{
    width: 250px;
    max-width: 1000px;
}

.links{
    color: red;
}

.cttwpp{
    cursor: pointer;
    border-radius: 8px;
    font-size: 18px;
}
